import * as React from "react"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `var(--color-bg-secondary)`,
      borderBottom: `1px solid var(--color-border-primary)`,
      marginBottom: `var(--space-4)`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: `var(--size-content)`,
        padding: `var(--space-4)`,
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: `center`,
      }}
    >
      <Link
        to="/"
        style={{
          color: `var(--color-text-primary)`,
          textDecoration: `none`,
          fontSize: `var(--font-xl)`,
          fontFamily: `var(--font-bold)`,
        }}
      >
        {siteTitle}
      </Link>
      <nav>
        <Link
          to="/"
          style={{
            color: `var(--color-text-secondary)`,
            textDecoration: `none`,
            fontSize: `var(--font-base)`,
            marginLeft: `var(--space-4)`,
          }}
          activeStyle={{
            color: `var(--color-accent-primary)`,
          }}
        >
          Blog
        </Link>
      </nav>
    </div>
  </header>
)

export default Header
