/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"

const Layout = ({ children, showHeader = true }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {showHeader && (
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      )}
      <div
        style={{
          margin: `0 auto`,
          maxWidth: `var(--size-content)`,
          padding: `var(--space-4)`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: `var(--space-5)`,
            padding: `var(--space-4)`,
            borderTop: `1px solid var(--color-border-primary)`,
            color: `var(--color-text-secondary)`,
            fontSize: `var(--font-sm)`,
            textAlign: "center",
          }}
        >
          © {new Date().getFullYear()} YouGenie. All rights reserved.
        </footer>
      </div>
    </>
  )
}

export default Layout
