import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const HomePage = ({ data }) => {
  const posts = data.allGraphCmsPost.nodes

  return (
    <Layout showHeader={false}>
      <div className={styles.intro}>
        <h1>YouGenie Blog</h1>
        <p>Discover the latest tips and tricks for content creation</p>
      </div>

      <div className={styles.list}>
        {posts.map(post => (
          <article key={post.slug} className={styles.listItem}>
            <Link to={`/posts/${post.slug}`} className={styles.listItemLink}>
              <h2 className={styles.listItemTitle}>{post.title}</h2>
              <p className={styles.listItemDescription}>{post.excerpt}</p>
              <p className={styles.listItemMeta}>
                By {post.author.name} •{" "}
                {new Date(post.date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            </Link>
          </article>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allGraphCmsPost(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        title
        excerpt
        date
        author {
          name
        }
      }
    }
  }
`

export const Head = () => <Seo title="Home" />

export default HomePage
